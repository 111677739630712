import React from 'react'
import classNames from "classnames"

const PaymentGatewaysRate = ({content}) => {

  return (
    <div className={classNames(
      "rate ",
    )}>

      {content.items.length && (
        <div className="rate__list">
          
          {
            content.items.map((item, i) => {
              return (
                <React.Fragment>
                  <div className={classNames(
                    "rate__item",
                  )} key={i}>
                    <div className="rate__value">
                      {item.valueBig && (<span className="rate__big">{item.valueBig}</span>)}
                      {item.valueStrong && (<span className="rate__strong">{item.valueStrong}</span>)}
                      {item.valueNormal && (<span>{item.valueNormal}</span>)}
                    </div>
                    <p className="rate__label">{item.label}</p>
                  </div>
                  {item.withPlus && (<p className="rate__plus">+</p>)}
                </React.Fragment>
              )
            })
          }

        </div>
      )}

      {content.description && (
        <p className="rate__desc">{content.description}</p>
      )}

    </div>
  )
}

export default PaymentGatewaysRate