import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import TwoColumn from "../../components/two-column"
import Robots from "../../components/robots"
import CustomerJourney from "../../components/customer-journey"
import PlanAction from "../../components/plan-action"
import StackedTexts from "../../components/stacked-texts"
import SingleContent from "../../components/single-column"
import PaymentGatewaysRate from "../../components/payment-gateways-rate"

const bannerContent1 = {
  title: (
    <>
      Billsby +{" "}
      <img
        src={require("../../images/paymentcloud@2x.png")}
        alt="Billsby + PaymentCloud"
      />{" "}
      = The simple subscription billing solution for high risk merchants
    </>
  ),
  description: [
    {
      text: (
        <>
          The subscription economy is incredibly exciting. But for merchants
          offering subscription services that are traditionally considered ‘high
          risk’ like <span>credit repair</span>, <span>adult products</span>,{" "}
          <span>guns and firearms</span>, <span>nutraceuticals</span>,{" "}
          <span>tech support</span>, <span>dating sites</span>,{" "}
          <span>cigars and tobacco</span> and <span>CBD</span>, it can feel
          impossible to get started. Not anymore.
        </>
      ),
    },
  ],
  buttons: [
    {
      isExternal: true,
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      buttonText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
  highRisk: true,
  image: require("../../images/master-cards-pocket@2x.png"),
  imageName: "Master Card",
  svg: true,
  backgroundColor: "#f3f5fb",
  textColor: "#252422",
  imagePosition: "right",
  isBanner: true,
  backgroundImage: require("../../images/master-cards-pocket@2x.png"),
  backgroundPosition: "right",
  hasBg: false,
}

const opportunity = {
  title: "Where some see only risk, we see opportunity.",
  list: [
    {
      title: "Feature rich subscription billing",
      description: (
        <>
          Whatever you need to grow your subscription business, you can do it
          with Billsby. We support everything from dunning when payments fail,
          to advanced price plans with products, plans and cycles, through to
          promotional tools to grow your business like discounts and coupons.
        </>
      ),
    },
    {
      title: "Openness and transparency",
      description: (
        <>
          We’ll be clear from the start about what you’ll pay for subscription
          billing, your payment gateway and your merchant account. No surprises.
          No ‘gotchas’. Just simple, clear billing that’s on the side of your
          high risk business with all of the support you need to grow.
        </>
      ),
    },
  ],
  image: "billsby-folder-girl.png",
  imageName: "billsby-payment",
}

const robotContent = {
  bgColor: "blue",
  title: "All the features you need to grow your business",
  titleColor: "white",
  block: [
    {
      img: require("../../images/checkout-with-billsbot.svg"),
      altText: "CHECKOUT AND PAYMENTS",
      text: "CHECKOUT AND PAYMENTS",
      url: "/product/subscribers/checkout-and-payments",
      svg: true,
    },
    {
      img: require("../../images/button-retention.svg"),
      altText: "DUNNING AND RETENTION",
      text: "DUNNING AND RETENTION",
      url: "/product/subscribers/dunning-retention",
      svg: true,
    },
    {
      img: require("../../images/button-addons.svg"),
      altText: "ADD-ONS AND ALLOWANCES",
      text: "ADD-ONS AND ALLOWANCES",
      url: "/product/creating/addons-allowances",
      svg: true,
    },
    {
      img: require("../../images/billsby-account-mgmt.svg"),
      altText: "ACCOUNT MANAGEMENT",
      text: "ACCOUNT MANAGEMENT",
      url: "/product/subscribers/account-management",
      svg: true,
    },
    {
      img: require("../../images/button-invoice-emails.svg"),
      altText: "INVOICES AND EMAILS",
      text: "INVOICES AND EMAILS",
      url: "/product/subscribers/invoices-creditnotes-emails",
      svg: true,
    },
  ],
}

const behindPaywalls = {
  image: require("../../images/support-talk.svg"),
  imageName: "billsby-payment",
  svg: true,
  stackedTexts: [
    {
      title: "We're here to help you get started, get setup and see quick ROI.",
      list: [
        {
          title: "",
          description: (
            <>
              Our account management team can help you to get setup with
              Billsby, answer your implementation questions and point you in the
              right direction to get started. If you're doing development in
              house, our developer community, email support, chat support and
              phone call-backs are included with every plan.
            </>
          ),
        },
        {
          title: "",
          description: (
            <>
              If you need development assistance, we can help with Pro
              Development Services too - our all-inclusive end-to-end developed
              for you solution that can integrate Billsby into your existing
              business and processes at a surprisingly affordable price. Let us
              know how we can help you to succeed.
            </>
          ),
        },
      ],
      buttons: [
        {
          scheduleButton: true,
          btnColor: "blue",
        },
        {
          btnColor: "blue",
          btnText: "Pro Development Services",
          urlType: "external",
          url: "/pro/development",
        },
      ],
    },
  ],
}

const singleContent3 = {
  title: "Explore away, there's no credit card required",
  text: (
    <>
      After signing up, head to{" "}
      <span>
        Settings {">"} Configuration {">"} Payment Gateways
      </span>{" "}
      for your one-click application to Payment Cloud to get your merchant
      account and payment gateway setup with Billsby’s preferential rates and
      service.
    </>
  ),
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
}

const solutionPlanActionContent = {
  title: "We’ve covered all three pieces of the puzzle for your business",
  description:
    "To get your high risk subscription billing business going - you need three components - subscription billing software, a payment gateway and a merchant account. We’ve covered all three.",
  image: require("../../images/plan-action.svg"),
  svg: true,
  imageName: "plan action image",
  // svg: true,
  cards: [
    {
      image: "plan-action-1.png",
      text: (
        <>
          Your <span>Authorize.net</span> payment gateway (provided by Payment
          Cloud)
        </>
      ),
    },
    {
      image: "plan-action-2.png",
      srcSet: "plan-action-2@2x.png 2x",
      text: (
        <>
          Your <span>Payment Cloud</span> merchant account with stellar support
        </>
      ),
    },
    {
      image: "plan-action-3.png",
      text: (
        <>
          And your best-in-class billing software, <span>Billsby</span>
        </>
      ),
    },
  ],
  buttons: [
    {
      isExternal: true,
      url: "https://app.billsby.com/registration",
      buttonColor: "orange",
      buttonText: "Sign up today",
    },
    {
      scheduleButton: true,
      buttonColor: "black",
    },
  ],
  comingSoonText: "",
}

const rateContent = {
  items: [
    {
      valueBig: "3.",
      valueStrong: "95%",
      valueNormal: " + $0.25",
      label: "Typical merchant account rate",
      withPlus: true,
    },
    {
      valueBig: "$19",
      valueStrong: "",
      valueNormal: " + $0.10",
      label: "Typical payment gateway rate",
      withPlus: true,
    },
    {
      valueBig: "0",
      valueStrong: ".4%",
      valueNormal: "",
      label: "Billsby’s industry leading fee",
      withPlus: false,
    },
  ],
  description: (
    <>
      <span>Plus $49 monthly fee for merchant account.</span> Typical rate for
      eligible high-risk merchants. Underwriting required. Well-established
      businesses with low chargeback rates may qualify for preferred pricing.
      $19 per month plus $0.10 per transaction applies to typical pricing for
      Authorize.net account acquired through PaymentCloud. Full terms and
      application form in your Billsby account.
    </>
  ),
}

const highRisk = () => {
  return (
    <Layout className="solutions high-risk">
      <SEO
        title="Subscription billing and recurring payments for high risk merchants | Billsby"
        description="Discover why Billsby is the ultimate subscription billing and recurring payments software for high risk merchants."
        url="https://www.billsby.com/solutions/high-risk-merchant-subscription-billing"
      />

      <TwoColumn content={bannerContent1} />
      <CustomerJourney content={opportunity} />
      <Robots content={robotContent} />

      <div className="explaining-story">
        <div classname="container">
          <h2>Our commitment to your high risk business</h2>
          <div className="bond-paper">
            <p>
              It’s amazing how many different kinds of businesses are classified
              as ‘high risk’, and you really shouldn’t let the term scare you.
              We’re able to offer subscription billing services regardless of
              what you do, so if you need high risk subscription billing for any
              industry, even things like:
            </p>

            <p>
              Accounting and tax prep, adult services, bail bonds, cigarettes
              and tobacco, credit repair, debt consolidation, digital downloads,
              document prep, drop shipping, guns and firearms, hunting and
              outdoor equipment, nutraceuticals and supplements, property
              management, SEO and SEM services, tech support, smoking
              accessories, vaping and e-cigarettes or really any classification
              of business that other subscription billing services might turn
              away, we’re here to help. We also won’t charge you a cent more
              than our regular prices - so Billsby is still just{" "}
              <span>
                0.4% of your transaction volume with a $5,000 free trial
              </span>
              .
            </p>

            <p>
              But we wanted to go further to support businesses who need a high
              risk merchant account for their subscription billing - whether new
              or established, so we setup a partnership with Payment Cloud.
              Payment Cloud are specialists in placing these kinds of accounts,
              and they’ll work with you to get you the absolute lowest rate that
              your business deserves.
            </p>

            <p>
              And once you’re all set, the tools in Billsby that help you manage
              dunning, send clear payment reminders to customers and control
              your account will all help you to minimise the risk of chargeback
              disputes and fraud, which in turn will mean your rate could get
              even lower at a free rate review.
            </p>

            <p>
              We’ve chosen Payment Cloud for this because they’ve got stellar
              service, their underwriting team will work as quickly as possible
              to get you setup, and they can get started with putting your
              account together straight away. You can request a PaymentCloud
              account in one-click from within your Billsby control panel.
            </p>

            <div className="feature-sign-holder">
              <img src={require("../../images/morgan.svg")} alt="morgan" />

              <div className="sign-holder">
                <img
                  className="sign-logo"
                  src={require("../../images/feature-tags-letter-signature.svg")}
                  alt="morgan"
                />
                <p>Head of Product and Business Development</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rate-holder">
        <div className="container">
          <PaymentGatewaysRate content={rateContent} />
        </div>
      </div>

      <PlanAction content={solutionPlanActionContent} />
      <StackedTexts content={behindPaywalls} />
      {/* <SingleContent content={singleContent3} /> */}
    </Layout>
  )
}

export default highRisk
